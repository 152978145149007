import request from "../utils/request";

export function loginByStudent(student_email, student_pwd) {
    return request.post("./index.php", {
        function: "loginByStudent",
        student_email,
        student_pwd,
    });
}

export function resetPassword(student_email, student_pwd) {
    return request.post("./index.php", {
        function: "resetPassword",
        student_email,
        student_pwd,
    });
}
export function registerStudent(params) {
    return request.post("./index.php", {
        function: "registerStudent",
        ...params
    });
}

export function searchAccounts(search_word, account_country) {
    return request.post("./index.php", {
        function: "searchAccounts",
        search_word,
        account_country
    });
}

export function getCountries() {
    return request.post("./index.php", {
        function: "getCountries",
    });
}

export function getStudentEntryList(student_id) {
    return request.post("./index.php", {
        function: "getStudentEntryList",
        student_id
    });
}

export function updateEntry(params) {
    return request.post("./index.php", {
        function: "updateEntry",
        ...params
    });
}

export function removeEntry(id) {
    return request.post("./index.php", {
        function: "removeEntry",
        id
    });
}

export function getApplicationInfo(application_id) {
    return request.post("./index.php", {
        function: "getApplicationInfo",
        application_id
    });
}

export function getStripeUrl(application_id) {
    return request.post("./index.php", {
        function: "getStripeUrl",
        application_id
    });
}


export function freePayApplication(application_id) {
    return request.post("./index.php", {
        function: "freePayApplication",
        application_id
    });
}


export function getEntry(id) {
    return request.post("./index.php", {
        function: "getEntry",
        id
    });
}

export function addEntryMember(entry_id, member_email, role) {
    return request.post("./index.php", {
        function: "addEntryMember",
        entry_id,
        member_email,
        role
    });
}

export function removeEntryMember(id) {
    return request.post("./index.php", {
        function: "removeEntryMember",
        id
    });
}

export function getProjectDetail(project_code, student_id) {
    return request.post('./index.php', {
        function: 'getProjectDetail',
        project_code,
        student_id
    })
}


export function getQNToken() {
    return request.post('./index.php', {
        function: 'getQNToken'
    })
}

export function uploadStudentTuitionTier(student_id, project_code, url) {
    return request.post('./index.php', {
        function: 'uploadStudentTuitionTier',
        student_id,
        project_code,
        url
    })
}


export function updateEntryMemberDescribe(data) {
    return request.post('./index.php', {
        function: 'updateEntryMemberDescribe',
        data
    })
}

export function getEntryMemberEntry(id) {
    return request.post('./index.php', {
        function: 'getEntryMemberEntry',
        id
    })
}



export function changePassword(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'changePassword' })
    return request.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function changeAdviserPassword(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'changeAdviserPassword' })
    return request.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}




export function updateStudentInfo(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'updateStudentInfo' })
    return request.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function updateAdviserInfo(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'updateAdviserInfo' })
    return request.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}


export function getStudentInfo(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getStudentInfo' })
    return request.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getAdviserInfo(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getAdviserInfo' })
    return request.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}